import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  checkout() {
    Paddle.Checkout.open({
      settings: {
        theme: "light"
      },
      items: [
        {
          priceId: this.data.get("priceId"),
          quantity: 1
        }
      ]
    })
  }
}
