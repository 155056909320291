// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"

import "@fortawesome/fontawesome-free/js/solid"
import "@fortawesome/fontawesome-free/js/brands"
import "@fortawesome/fontawesome-free/js/fontawesome"
FontAwesome.config.mutateApproach = 'sync'

import "./controllers"
